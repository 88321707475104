import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthLogService } from '../services/authLog.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private auth: AuthLogService) {
      
  }

canActivate(): boolean {
    if (this.auth.estaAutenticado()) {
        return true;
    } else {
        this.router.navigate(['login']);
        return false;
    }
  }
}
