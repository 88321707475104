import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class ApiService {

  constructor(private http: HttpClient) { }


  public GetUser() {
    return this.http.post<any>(environment.apiURL + "/Cliente/ObtenerCliente", {});
  }

  public GetInfoCliente() {
    return this.http.get<any>(environment.apiURL + "/articulos/GetCliente", {});
  }

  public GetArticles(idPantalla) {
    return this.http.get<any>(`${environment.apiURL}/articulos/getarticulos?idPantalla=${idPantalla}`);
  }

  public GetIdCabecera() {

    return this.http.get<any>(`${environment.apiURL}/articulos/GetIdCabecera`, {});
  }

  public UpdateCabeceraTicket() {
    let data = {
      "Parametros": " ",
    }
    return this.http.post<any>(`${environment.apiURL}/articulos/UpdateCabeceraTicket`, data);
  }

  public GetListDetalles() {
    return this.http.get<any>(`${environment.apiURL}/articulos/GetDetallesTicket`);
  }

  public GetListPantallas() {
    return this.http.get<any>(`${environment.apiURL}/articulos/GetPantallas`);
  }

  public ObtenerPrecioTarifa(ArticuloDetalle) {
    return this.http.post<any>(`${environment.apiURL}/articulos/ObtenerTarifa`, ArticuloDetalle);
  }

  public ActualizarOInsertarTicket(ArticuloDetalle) {
    return this.http.post<any>(`${environment.apiURL}/articulos/ActualizarOinsertarTicket`, ArticuloDetalle);
  }

  public EliminarTicketDetalle(id) {
    let data = {
      idDetalleTicket: id
     }
    return this.http.post<any>(`${environment.apiURL}/articulos/EliminarDetalleTicket/${id}`, data);
  }

  public AnularTicket() {
    return this.http.post<any>(`${environment.apiURL}/articulos/AnularTicket`, {});
  }

  public CrearPedido(fecha) {
    return this.http.post<any>(`${environment.apiURL}/pedido/CrearPedido/`,fecha);
  }

  public GetVentasActivas() {
    return this.http.get<any>(`${environment.apiURL}/pedido/GetVentasActivas`, );
  }

  public GetPedidosPendientes(FechaIni: string, Fechafin: string, NumDoc: string = "") {
    let data: any = {
      FechaIni: FechaIni, Fechafin: Fechafin, NumDoc: NumDoc
    }
    return this.http.get<any>(`${environment.apiURL}/pedido/GetPedidosPendientes` + this.toParams(data) );
  }

  public ObtenerTicketsEnCurso() {
    return this.http.post<any>(`${environment.apiURL}/pedido/ObtenerTicketsEnCurso`, {});
  }

  public GetDetallesPedidosPendiente(idPedidoVenta) {
    return this.http.get<any>(`${environment.apiURL}/pedido/GetDetallesPedidos?idpedv=${idPedidoVenta}`);
  }

  public ActualizarPedido(fecha, DatosPedido) {
    return this.http.post<any>(`${environment.apiURL}/pedido/ActualizarPedido?fecha=${fecha}`, DatosPedido);
  }

  public actualizarObservacionesPedidos(tipoPedido,cabecera ) {
    return this.http.post<any>(`${environment.apiURL}/pedido/actualizarObservacionesPedidos?tipoPedido=${tipoPedido}`, cabecera);
  }

  public VerDocumentoPedido(idPedido ) {
    return this.http.post<any>(`${environment.apiURL}/pedido/VerDocumentoPedido?idPedido=${idPedido}`, {});
  }

  public GetDireccionClienteById() {
    return this.http.get<any>(`${environment.apiURL}/Cliente/GetDireccionClienteById`);
  }

  public GetRepresentates() {
    return this.http.get<any>(`${environment.apiURL}/Cliente/GetRepresentantes`);
  }

  public GetTransportistas() {
    return this.http.get<any>(`${environment.apiURL}/Cliente/GetTransportistas`);
  }

  public getListaAlbaranes(data) {
    return this.http.post<any>(environment.apiURL + "/Pedido/Obteneralbaran", data);
  }

  public GetDetalleAlbaran(idAlbv) {
    return this.http.post<any>(
      environment.apiURL + "/Pedido/ObtenerDetalleAlbaran",
      JSON.stringify(idAlbv.toString())
    );
  }

  public GetPDFAlbaran(idAlbv) {
    return this.http.post<any>(
      `${environment.apiURL}/Pedido/ObtenerDocumentoAlbaran/?idAlbv=${idAlbv}`, {}
    );
  }

  public getListaFacturas(fechaInicial,fechaFinal ) {
    let data = {
      "fechaInicial": fechaInicial,
      "fechaFinal": fechaFinal,
    }
    return this.http.post<any>(environment.apiURL + "/Pedido/ObtenerFactura", data);
  }

  public GetPDFFactura(idFacv) {
    return this.http.post<any>(
      `${environment.apiURL}/Pedido/ObtenerDocumentoFactura/?idFacv=${idFacv}`, {}
    );
  }

  public GetDetalleFactura(idFacv) {
    return this.http.post<any>(
      environment.apiURL + "/Pedido/ObtenerDetalleFactura",
      JSON.stringify(idFacv.toString())
    );
  }

  public getPdfPedido(idpedv) {
    return this.http.post<any>(
      environment.apiURL + "/Pedido/ObtenerDocumentoPedido?idpedv=" + idpedv,
      {}
    );
  }

  public getCartera() {
    return this.http.get<any>(environment.apiURL + "/Pedido/getcartera");
  }

  public getCarteraAgrupacion(procedeId) {
    return this.http.get<any>(environment.apiURL + "/Pedido/getcarteraagrupacion?idAgrupacion=" + procedeId);
  }

  public getRiesgoCliente() {
    return this.http.get<any>(environment.apiURL + "/riesgo/GetRiesgoCliente");

  }

  public GetEfectosPendietesDetalle() {
    return this.http.get<any>(environment.apiURL + "/riesgo/GetEfectosPendietesDetalle");
  }

  public CrearSolicitudRiesgo(data) {
    return this.http.post<any>(environment.apiURL + "/riesgo/CrearSolicitudRiesgo", data);
  }

  public GetColorDesbloqueo() {
    return this.http.get<any>(environment.apiURL + "/riesgo/GetColorDesbloqueo");
  }

  public toParams(obj: any) {
    const str = [];

    if (Object.keys(obj).length === 0) {
      return '';
    }

    for (const p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }

    return '?' + str.join('&');
  }

  public loginNoSocial(usuario: string, password: string) {
    let data = {
      usuario: usuario,
      password: password,
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    return this.http.post<any>(
      environment.apiURL + "/users/AuthenticateUsrPwd/", 
      data, options
    );
  }

}
