import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {  AuthGuard } from './interceptors/auth.guard';

const routes: Routes = [

    { path: '', redirectTo: 'pedido', pathMatch: 'full' },
    // { path: 'venta', loadChildren: () => import('./modules/venta/venta.module').then(m => m.VentaModule)},
    { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},
    { path: 'pedido', loadChildren: () => import('./modules/pedido/pedido.module').then(m => m.PedidoModule), canActivate: [AuthGuard] },
    { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)},
    { path: 'terminos', loadChildren: () => import('./modules/terminos/terminos.module').then(m => m.TerminosModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
