import {
  HttpErrorResponse,
  HttpInterceptor,
  HttpResponse,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpHeaders
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class InterceptorService implements HttpInterceptor {
  constructor(public router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    });
    const reqClone = req.clone({
      headers
    });
    return next.handle(reqClone).pipe(
      tap(
        (event: HttpEvent<any>) => {
          // if (event instanceof HttpResponse) {
          // }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              localStorage.removeItem("token");
              this.router.navigate(["login"]);
            }
          }
        }
      )
    );
  }
}
