import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'ventas-activas',
  templateUrl: './ventas-activas.component.html',
  styleUrls: ['./ventas-activas.component.scss']
})
export class VentasActivasComponent implements OnInit {
  @Input() public widthContenedor: number;
  @Input() public widthContenedorGrid: number;
  @Input() public heightContenedorGrid: number;

  ventasActivas: any[] = [];
  @BlockUI('ventas-activas-section') blockUIVentas: NgBlockUI;
  constructor(private api: ApiService) {
    console.log(this.heightContenedorGrid);
    this.cargarVentasActivas();
  }

  ngOnInit() {
  }

  cargarVentasActivas() {
    this.blockUIVentas.start("Cargando ventas activas");
    this.api.GetVentasActivas().subscribe(data => {
      this.ventasActivas = data;
      this.blockUIVentas.stop();
    }, error => {
        console.log(error);
        this.blockUIVentas.stop();
    });
  }
}
