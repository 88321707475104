export class Authenticate {
    public Provider: string
    public Token: string
    public TokenEmpresa: string

    constructor(data?: any) {
        if (data) {
            this.Provider = data.Provider;
            this.Token = data.Token;
            this.TokenEmpresa = data.TokenEmpresa;
        }
    }
}
