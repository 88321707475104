import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import swal from 'sweetalert2';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'ticket-en-edicion',
  templateUrl: './ticket-en-edicion.component.html',
  styleUrls: ['./ticket-en-edicion.component.scss']
})
export class TicketEnEdicionComponent implements OnInit {

  Pedidos: any[] = [];

 @Output() seleccionarPedido:EventEmitter<any> = new EventEmitter<any>();

  @Input() public widthContenedor: number;
  @Input() public widthContenedorGrid: number;
  @Input() public heightContenedorGrid: number;
  @BlockUI('tickete-edicion-section') blockUIPedidos: NgBlockUI;
  constructor(private api: ApiService) {}


  ngOnInit() {
    this.cargarpedidos();
  }


  cargarpedidos() {
    this.api.ObtenerTicketsEnCurso().subscribe(data => {
      this.Pedidos = data;
      this.blockUIPedidos.stop();
    }, error => {
        console.log(error);
        this.mostrarMensajeError("Ocurrio un error al cargar pedidos, vuelva a intentarlo");
        this.blockUIPedidos.stop()
    });
  }

  selectCelda(Pedido) {
     this.seleccionarPedido.emit(Pedido)
  }

  mostrarMensajeError(msj) {
    swal.fire(
      'Error',
      msj,
      'error'
    )
  }
}

