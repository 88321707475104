import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthLogService } from './services/authLog.service';
import { InterceptorService } from './interceptors/interceptor';
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';

import { ApiService } from 'src/app/services/api.service';
import { AuthGuard } from './interceptors/auth.guard';

import { AngularSplitModule } from 'angular-split';

registerLocaleData(localeES, 'es');

let config = new AuthServiceConfig([
    /*{
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider("329106866474-hk8sik5hrkfa7ncs4bn8rbk7tp11nq1k.apps.googleusercontent.com")
    },
    {
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider("3217928824957653")
    }*/
]);

export function provideConfig() {
    return config;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [

    AngularSplitModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    SocialLoginModule,
    HttpClientModule,
  ],
  providers: [AuthLogService, ApiService, AuthGuard,
    {
        provide: AuthServiceConfig,
        useFactory: provideConfig,
    },
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
