import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'detalle-articulos',
  templateUrl: './detalle-articulos.component.html',
  styleUrls: ['./detalle-articulos.component.scss']
})
export class DetalleArticulosComponent implements OnInit {
  @Input() public articuloDetalle: any = {}
  @Output() addArticulo = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  addArticuloEvent(articulo){
    this.addArticulo.emit(articulo)
  }
}
