import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { SocialUser } from 'angularx-social-login';
import { Authenticate } from '../class/authenticate';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthLogService {

  userToken: string;

  constructor( private http: HttpClient ) {
    this.leerToken();
  }


  logout() {
    localStorage.removeItem('token');
  }

  login( UserSocial: SocialUser,Provider: string, TokenEmpresa:string = ''  ) {

    if (TokenEmpresa == ''){
      TokenEmpresa= 'vacio';
    }

    let dataToken = new Authenticate({
      Provider: Provider,
      TokenEmpresa: TokenEmpresa,
      Token: UserSocial.authToken
    });

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<any>(
      `${ environment.apiURL }/users/authenticate/`,JSON.stringify(dataToken), options)
      .pipe(
        map( resp => {
          this.guardarToken( resp.Token );// resp['fechaCaducidad']
          return resp;
        })
      );

  }

  private guardarToken( idToken: string ) {

    this.userToken = idToken;
    localStorage.setItem('token', idToken);
  }

  leerToken() {
    if ( localStorage.getItem('token') ) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }


estaAutenticado(): boolean {
    if ( this.userToken.length < 2 ) {
        return false;
    }
    return true;
}


}
