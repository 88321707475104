import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import swal from 'sweetalert2';

@Component({
  selector: 'pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss']
})
export class PedidosComponent implements OnInit {

  now = new Date();

  fechaInicio = new FormControl(new Date(this.now.setDate(this.now.getDate() - 7)));
  fechaFin = new FormControl(new Date());
  NumDoc = '';
  Pedidos: any[] = [];
  @Output() addPedido:EventEmitter<any> = new EventEmitter<any>();
  @Output() editarObservaciones: EventEmitter<any> = new EventEmitter<any>();

  @Input() public widthContenedor: number;
  @Input() public widthContenedorGrid: number;
  @Input() public heightContenedorGrid: number;

  @BlockUI('pedido-section') blockUIPedidos: NgBlockUI;
  constructor(private api: ApiService) {
  }

  ngOnInit() {
  }

  cargarpedidos() {
    this.blockUIPedidos.start("Cargando Pedidos");
    let fechaInicio = new Date(this.fechaInicio.value).toLocaleDateString();
    let fechaFin = new Date(this.fechaFin.value).toLocaleDateString();
    let NumDoc = this.NumDoc == "" ? "  " : this.NumDoc;
    this.api.GetPedidosPendientes(fechaInicio, fechaFin, NumDoc).subscribe(data => {
      data.forEach((element, i) => {
        data[i].clase = this.agregarClasesParaCeldas(element);
      });
      this.Pedidos = data;
      this.blockUIPedidos.stop()
    }, error => {
        console.log(error);
        this.mostrarMensajeError("Ocurrio un error al cargar pedidos, vuelva a intentarlo");
        this.blockUIPedidos.stop()
    });
  }

  selectCelda(Pedido) {
    this.addPedido.emit(Pedido)
  }

  editarObservacionesEmit(Pedido) {
    this.editarObservaciones.emit(Pedido)
  }

  descargarFactura(idPedv) {
    this.blockUIPedidos.start("Descargando Archivo, por favor espere");
    this.api.VerDocumentoPedido(idPedv).subscribe(response => {
      const downloadLink = document.createElement("a");
      downloadLink.href = response;
      downloadLink.download = `factura_${idPedv}.pdf`;
      downloadLink.click();
      this.blockUIPedidos.stop();
    }, error => {
      this.blockUIPedidos.stop();
        this.mostrarMensajeError("Ocurrio un error al descargar el archivo, vuelva a intentarlo");
        console.log(error);
    });
  }

  validarFechaInicio(newdate) {
    let fechaNueva = new Date(newdate);
    //let fechaActual = new Date();
    let fechaTope = new Date(this.fechaFin.value);
    if (fechaNueva > fechaTope)
    {
      const now = new Date();
      this.fechaInicio = new FormControl(new Date(now.setDate(now.getDate() - 7)));
    }
  }

  validarFechaFin(newdate) {
    let fechaNueva = new Date(newdate);
    let fechaInicial = new Date(this.fechaInicio.value);
    let fechaActual = new Date();
    if (fechaNueva < fechaInicial)
    {
      this.fechaFin = new FormControl(new Date());
    }
  }

  mostrarMensajeError(msj) {
    swal.fire(
      'Error',
      msj,
      'error'
    )
  }

  agregarClasesParaCeldas(cabecera) {
    let clase: string = "";
    console.log(cabecera);
    if (cabecera.lanzado) {
      clase = 'lanzado-true';
    }
    else if (!cabecera.lanzado) {
      clase = 'lanzado-false';
    }
    else if (cabecera.finalizado) {
      clase = 'finalizado';
    }
    else if (cabecera.modificado) {
      clase = 'modificado';
    }
    else if (cabecera.situacion == "S") {
      clase = 'situacion';
    }
    else if (!cabecera.preparado) {
      clase = 'preparado';
    } else {
      clase = '';
    }
    return clase;
  }
}
