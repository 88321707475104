import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import { CabeceraArticulosComponent } from './../component/cabecera-articulos/cabecera-articulos.component';
import { DetalleArticulosComponent } from './../component/detalle-articulos/detalle-articulos.component';
import { VentasActivasComponent } from './../modules/ventas-activas/ventas-activas.component';
import { PedidosComponent } from './../modules/pedidos/pedidos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule, MatPaginatorModule, MatSnackBarModule } from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BlockUIModule } from 'ng-block-ui';
import {MatMenuModule} from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { TicketEnEdicionComponent } from '../modules/ticket-en-edicion/ticket-en-edicion.component';

@NgModule({
  declarations: [CabeceraArticulosComponent, DetalleArticulosComponent, VentasActivasComponent, PedidosComponent, TicketEnEdicionComponent],
  imports: [
    BlockUIModule.forRoot(),
    CommonModule,
    AngularFontAwesomeModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule
  ],
  exports: [
    CommonModule,
    AngularFontAwesomeModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    CabeceraArticulosComponent,
    DetalleArticulosComponent,
    VentasActivasComponent,
    PedidosComponent,
    TicketEnEdicionComponent,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    BlockUIModule,
    MatMenuModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule
  ]
})
export class SharedModule {}
